<template>
  <b-modal
    id="form-property"
    :visible="shallShowPropertyFormModal"
    :title="`${$t('Form')} ${$t('Property')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-property-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typePropertyForm)} ${$t('Property')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-property-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typePropertyForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typePropertyForm === 'Add' || typePropertyForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: Type -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Type')"
                      label-for="property-type"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Type')"
                        rules="required"
                      >
                        <v-select
                          id="property-type"
                          v-model="propertyData.property_type_id"
                          :options="propertyData.typeOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :placeholder="$t('Type')"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Type')"
                      label-for="property-type"
                    >
                      <span class="form-info-box">{{ propertyData.type }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Code -->
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      :label="$t('Property Code')"
                      label-for="property-code"
                    >
                      <span class="form-info-box">{{ propertyData.code }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: License -->
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('License Number')"
                      label-for="property-license"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('License Number')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-license"
                          v-model="propertyData.license"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('License Number')"
                      label-for="property-license"
                    >
                      <span class="form-info-box">{{ propertyData.license }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Status -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="property-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="propertyData.status"
                          name="property-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="propertyData.status"
                          name="property-status"
                          value="inactive"
                          class="custom-control-info cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="property-status"
                    >
                      <span class="form-info">
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(propertyData.status)}`"
                        >
                          {{ $t(textFirstUpper(propertyData.status)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Deleted -->
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Set Deleted')"
                      label-for="property-is-deleted"
                    >
                      <b-form-checkbox
                        v-model="propertyData.is_deleted"
                        switch
                        inline
                      >
                        <span>{{ $t(deletedToText(propertyData.is_deleted)) }}</span>
                      </b-form-checkbox>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Set Deleted')"
                      label-for="property-is-deleted"
                    >
                      <span class="form-info">
                        <b-badge
                          pill
                          :variant="`light-${deletedVariant(propertyData.is_deleted)}`"
                        >
                          {{ $t(deletedToText(propertyData.is_deleted)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Project -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Project')"
                      label-for="property-project"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Project')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-project"
                          v-model="propertyData.project"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Project')"
                      label-for="property-project"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="propertyData.project" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Guarantee Price -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Guarantee Price')"
                      label-for="property-guarantee-price"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Guarantee Price')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-guarantee-price"
                          v-model="propertyData.guarantee_price"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Guarantee Price')"
                      label-for="property-guarantee-price"
                    >
                      <span class="form-info-box">{{ propertyData.guarantee_price }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Consignment Price -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Consignment Price')"
                      label-for="property-consignment-price"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Consignment Price')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-consignment-price"
                          v-model="propertyData.consignment_price"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Consignment Price')"
                      label-for="property-consignment-price"
                    >
                      <span class="form-info-box">{{ propertyData.consignment_price }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Size -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Size')"
                      label-for="property-size"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Size')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-size"
                          v-model="propertyData.size"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Size')"
                      label-for="property-size"
                    >
                      <span class="form-info-box">{{ propertyData.size }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Usable Area -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Usable Area')"
                      label-for="property-usable-area"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Usable Area')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-usable-area"
                          v-model="propertyData.usable_area"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Usable Area')"
                      label-for="property-usable-area"
                    >
                      <span class="form-info-box">{{ propertyData.usable_area }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Address -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Address')"
                      label-for="property-address"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Address')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-address"
                          v-model="propertyData.address"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Address')"
                      label-for="property-address"
                    >
                      <span class="form-info-box">{{ propertyData.address }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: City -->
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('City')"
                      label-for="property-city"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('City')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-city"
                          v-model="propertyData.city"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('City')"
                      label-for="property-city"
                    >
                      <span class="form-info-box">{{ propertyData.city }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: District -->
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('District')"
                      label-for="property-district"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('District')"
                        rules="required"
                      >
                        <b-form-input
                          id="property-district"
                          v-model="propertyData.district"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('District')"
                      label-for="property-district"
                    >
                      <span class="form-info-box">{{ propertyData.district }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Province -->
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Province')"
                      label-for="property-province"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Province')"
                        rules="required"
                      >
                        <v-select
                          id="property-province"
                          v-model="propertyData.province"
                          :options="propertyData.provinceOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :placeholder="$t('Province')"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Province')"
                      label-for="property-province"
                    >
                      <span class="form-info-box">{{ propertyData.province }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Description -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Description')"
                      label-for="property-description"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Description')"
                        rules="required"
                      >
                        <b-form-textarea
                          id="property-description"
                          v-model="propertyData.description"
                          rows="2"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          style="height: 60px;"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Description')"
                      label-for="property-description"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="propertyData.description" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Remark -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Remark')"
                      label-for="property-remark"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Remark')"
                        rules="required"
                      >
                        <b-form-textarea
                          id="property-remark"
                          v-model="propertyData.remark"
                          rows="2"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          style="height: 60px;"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Remark')"
                      label-for="property-remark"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="propertyData.remark" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Member -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('Member')"
                      label-for="property-member"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Member')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="propertyData.member_id"
                          style="display: none;"
                        />
                        <b-input-group class="m-0">
                          <b-input-group-prepend
                            is-text
                            style="cursor: pointer;"
                            @click="selectMember"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="12"
                            />
                          </b-input-group-prepend>
                          <b-form-input
                            id="property-member"
                            ref="refMember"
                            v-model="propertyData.member"
                            :state="getValidationState(validationContext)"
                            :disabled="true"
                            style="background: #f8f8f8 !important;"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Member')"
                      label-for="property-member"
                    >
                      <span class="form-info-box">{{ propertyData.member }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: State -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typePropertyForm === 'Add' || typePropertyForm === 'Edit')"
                      :label="$t('State')"
                      label-for="property-state"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('State')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="propertyData.state"
                          name="property-state"
                          value="request"
                          class="custom-control-info cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Request') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="propertyData.state"
                          name="property-state"
                          value="approve"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Approve') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="propertyData.state"
                          name="property-state"
                          value="reject"
                          class="custom-control-warning cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Reject') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="propertyData.state"
                          name="property-state"
                          value="cancel"
                          class="custom-control-danger cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Cancel') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('State')"
                      label-for="property-state"
                    >
                      <span class="form-info">
                        <b-badge
                          pill
                          :variant="`light-${stateVariant(propertyData.state)}`"
                          class="text-capitalize"
                        >
                          {{ $t(stateToText(propertyData.state)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <!-- Member Select -->
    <member-select
      v-model="showModalMemberSelect"
      :title="`${$t('Select')} ${$t('Member')}`"
      :member-data="memberData"
      @update-member-select="updateMemberSelect"
      @discard-member-select="discardMemberSelect"
    />

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BBadge,
  BFormTextarea,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import MemberSelect from '@/views/crm/member/MemberSelect.vue'
import tableProperty from './tableProperty'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BBadge,
    BFormTextarea,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    vSelect,

    MemberSelect,
  },
  model: {
    prop: 'shallShowPropertyFormModal',
    event: 'update:shall-show-property-form-modal',
  },
  props: {
    shallShowPropertyFormModal: {
      type: Boolean,
      required: true,
    },
    typePropertyForm: {
      type: String,
      default: 'Add',
    },
    propertyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      showModalMemberSelect: false,
      memberData: {},
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      const typeOption = this.propertyData.typeOptions.find(x => x.value === this.propertyData.property_type_id)
      if (typeOption !== undefined) {
        this.propertyData.type = typeOption.label
      }
      delete this.propertyData.provinceOptions
      delete this.propertyData.typeOptions
      delete this.propertyData.request_by
      delete this.propertyData.request_datetime
      delete this.propertyData.approve_by
      delete this.propertyData.approve_datetime
      delete this.propertyData.reject_by
      delete this.propertyData.reject_datetime
      delete this.propertyData.cancel_by
      delete this.propertyData.cancel_datetime

      if (this.typePropertyForm === 'Add') {
        store.dispatch('store-property/addProperty', this.propertyData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$swal({
              icon: 'success',
              title: this.$i18n.t('Added'),
              text: this.$i18n.t('Your data has been added'),
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

            this.$emit('property-form-update')
            this.$emit('update:shall-show-property-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typePropertyForm === 'Edit') {
        store.dispatch('store-property/editProperty', { id: this.propertyData.id, data: this.propertyData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('property-form-update')
            this.$emit('update:shall-show-property-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typePropertyForm === 'Delete') {
        store.dispatch('store-property/deleteProperty', {
          id: this.propertyData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('property-form-update')
            this.$emit('update:shall-show-property-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-property-form')
      this.$emit('update:shall-show-property-form-modal', false)
    },
    updateMemberSelect(data) {
      this.$nextTick(() => {
        this.propertyData.member_id = data.select.id
        this.propertyData.member = `${data.select.prefix} ${data.select.name} ${data.select.lastname}`
        this.memberData.id = this.propertyData.member_id
        this.memberData.fullname = this.propertyData.member
        this.$refs.refForm.focus()
      })
    },
    discardMemberSelect() {
    },
    selectMember() {
      if (this.propertyData.member_id === '') {
        this.memberData = {}
      } else {
        this.memberData.id = this.propertyData.member_id
        this.memberData.fullname = this.propertyData.member
      }
      this.showModalMemberSelect = true
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      deletedToText,
      deletedVariant,
      stateToText,
      stateVariant,
    } = tableProperty()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      deletedToText,
      deletedVariant,
      stateToText,
      stateVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}
.form-info{
  display: flex;
  padding: 0.6rem 1rem;
  border: 0px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>

<template>
  <b-modal
    id="property-image"
    :visible="showPropertyImageModal"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:show-property-image-modal', val)"
  >
    <image-light-box
      ref="refImageLightBox"
      :show-light-box="false"
      :show-caption="true"
      :show-thumbs="false"
      :disable-zoom="false"
      :media="mediaLightBox"
    />
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $attrs.title }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div />

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>
    <!-- Modal: Body -->
    <div>
      <div>
        <b-card
          class="p-0 m-0"
          style="margin-top: 0.8rem !important;"
        >
          <b-card-title>
            <small
              v-if="propertyData.project !== undefined"
            >{{ `${$t('Property')}` }}</small>
            <b-alert
              v-if="propertyData.project !== undefined"
              show
              variant="success"
              style="margin-top: 0.2rem;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">{{ propertyData.project }}</span>
              </div>
            </b-alert>
          </b-card-title>

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <label>{{ $t('Entries') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOption"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                />
              </b-col>

              <!-- Type -->
              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                  <b-button
                    v-if="$can('create', 'Property')"
                    variant="primary"
                    class="w-50"
                    @click="addPropertyImage"
                  >
                    {{ `${$t('Add')} ${$t('Image')}` }}
                  </b-button>
                  <v-select
                    v-model="typeImage"
                    :options="typeImageOptions"
                    :clearable="true"
                    class="d-inline-block ml-50 mr-1"
                    style="width: 200px;"
                    :placeholder="$t('Type')"
                    :reduce="val => val.value"
                  />
                </div>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refTable"
            :items="propertyImageData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            hover
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            selectable
            select-mode="multi"
          >
            <!-- Column: Type -->
            <template #cell(type)="data">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 16px;">
                      <b-badge
                        pill
                        variant="light-primary"
                      >
                        {{ typeToText(data.item.type) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Sort') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-success"
                        class="text-capitalize"
                      >
                        {{ data.item.sort }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('ID') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-info"
                        class="text-capitalize"
                      >
                        {{ data.item.id }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Image -->
            <template #cell(image)="data">
              <!-- Image -->
              <div style="margin-top: 0.3rem;">
                <b-link
                  class="nav-link"
                  @click="showPopupImage(data.item)"
                >
                  <b-badge
                    variant="light-info"
                    style="padding: 8px 15px 8px 15px;"
                  >
                    <b-img
                      :src="data.item.image_url"
                      style="height: 100px;"
                    />
                  </b-badge>
                </b-link>
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div
                class="text-nowrap"
                style="margin-top: 15px; text-align: left;"
              >
                <feather-icon
                  v-if="$can('delete', 'Property')"
                  :id="`property-image-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="deletePropertyImage(data)"
                />
                <b-tooltip
                  v-if="$can('delete', 'Property')"
                  :title="`${$t('Delete')} ${$t('Image')}`"
                  :target="`property-image-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="right"
                />

              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  {{ $t('Showing {from} to {to} of {of} entries',
                        {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                  }}
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItem"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </div>
    </div>

    <property-image-form
      v-model="shallShowPropertyImageFormModal"
      :image-data="imageData"
      :type-property-imageform="typePropertyImageForm"
      @property-image-form-update="updatePropertyImageForm"
      @discard-property-image-form="discardPropertyImageForm"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BPagination,
  BAlert,
  BTooltip,
  BListGroup,
  BListGroupItem,
  BBadge,
  BImg,
  BLink,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageLightBox from 'vue-image-lightbox'
import storeProperty from './storeProperty'
import PropertyImageForm from './PropertyImageForm.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    BAlert,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BBadge,
    BImg,
    BLink,

    vSelect,
    ImageLightBox,
    PropertyImageForm,
  },
  model: {
    prop: 'showPropertyImageModal',
    event: 'update:show-property-image-modal',
  },
  props: {
    showPropertyImageModal: {
      type: Boolean,
      required: true,
    },
    propertyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      busy: false,
      tableColumns: [],
      perPage: 10,
      totalItem: 0,
      currentPage: 1,
      perPageOption: [2, 5, 10, 25, 50, 100],
      typeImage: '',
      sortBy: 'type_sort',
      isSortDirDesc: false,
      locale: 'th',
      propertyImageData: [],
      mediaLightBox: [{}],
      shallShowPropertyImageFormModal: false,
      imageData: {},
      typePropertyImageForm: '',
      typeImageOptions: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTable ? this.$refs.refTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    typeImage() {
      this.refetchData()
    },
    locale() {
      this.refetchData()
    },
    propertyData() {
      this.getListPropertyImage()
    },
  },
  mounted() {
    const msgList = {
      type: `${this.$i18n.t('Type')} » ${this.$i18n.t('Sort')} » ${this.$i18n.t('ID')}`,
      image: `${this.$i18n.t('Image')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      { key: 'type', sortable: true, label: msgList.type },
      { key: 'image', sortable: false, label: msgList.image },
      { key: 'actions', label: msgList.actions },
    ]
    this.getDefaultPropertyImage()
  },
  methods: {
    getDefaultPropertyImage() {
      this.showOverlay = true
      store
        .dispatch('store-property/getDefaultPropertyImage', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.typeImageOptions = response.data.typeImageOptions
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    getListPropertyImage() {
      const params = {
        propertyId: this.propertyData.id,
        type: this.typeImage,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
      }
      store
        .dispatch('store-property/getListPropertyImage', params)
        .then(response => {
          const { list, total } = response.data
          this.propertyImageData = list
          this.totalItem = total
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    typeToText(type) {
      if (type === 'property') return this.$i18n.t('Property')
      if (type === 'map') return this.$i18n.t('Map')
      if (type === 'document') return this.$i18n.t('Document')
      return ''
    },
    onDiscard() {
      this.$emit('discard-property-image')
      this.$emit('update:show-property-image-modal', false)
    },
    addPropertyImage() {
      this.showOverlay = true
      store
        .dispatch('store-property/getDefaultPropertyImage', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.imageData = response.data
          this.imageData.image_data = ''
          this.imageData.image_url = ''
          this.imageData.property_id = this.propertyData.id
          this.typePropertyImageForm = 'Add'
          this.shallShowPropertyImageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deletePropertyImage(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-property/deletePropertyImage', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    refetchData() {
      this.getListPropertyImage()
    },
    showPopupImage(data) {
      const image = [
        {
          src: data.image_url,
        },
      ]
      this.mediaLightBox = image
      this.$refs.refImageLightBox.showImage(0)
    },
    updatePropertyImageForm() {
      this.refetchData()
    },
    discardPropertyImageForm() {
      this.refetchData()
    },
  },
  setup() {
    if (!store.hasModule('store-property')) store.registerModule('store-property', storeProperty)
    onUnmounted(() => {
      if (store.hasModule('store-property')) store.unregisterModule('store-property')
    })
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.property-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>

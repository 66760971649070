import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableProperty() {
  // Use toast
  const toast = useToast()

  const refPropertyTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('Property') }),
    column1: `${t('Date/Time')} » ${t('Member')}  » ${t('Type')} » ${t('Property Code')} » ${t('License Number')}`,
    column2: `${t('Project')} » ${t('Address')} » ${t('Description')} » ${t('Remark')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'column1', sortable: true, label: msgList.column1 },
    { key: 'column2', sortable: false, label: msgList.column2 },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalPropertys = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refPropertyTable.value ? refPropertyTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPropertys.value,
    }
  })

  const refetchData = () => {
    refPropertyTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListProperty = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-property/getListProperty', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalPropertys.value = total
        localStorage.setItem('filterProperty', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = isstatus => {
    if (isstatus === 'active') return 'success'
    if (isstatus === 'inactive') return 'danger'
    return 'danger'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const deletedToText = deleted => {
    if (deleted === false) return 'No'
    if (deleted === true) return 'Deleted'
    return ''
  }
  const deletedVariant = deleted => {
    if (deleted === false) return 'secondary'
    if (deleted === true) return 'danger'
    return 'danger'
  }

  const stateToText = state => {
    if (state === 'request') return 'Request'
    if (state === 'approve') return 'Approve'
    if (state === 'reject') return 'Reject'
    if (state === 'cancel') return 'Cancel'
    return ''
  }
  const stateVariant = state => {
    if (state === 'request') return 'info'
    if (state === 'approve') return 'success'
    if (state === 'reject') return 'warning'
    if (state === 'cancel') return 'danger'
    return 'secondary'
  }

  return {
    getListProperty,
    tableColumns,
    perPage,
    currentPage,
    totalPropertys,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refPropertyTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
    deletedToText,
    deletedVariant,
    stateToText,
    stateVariant,
  }
}

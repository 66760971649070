<template>
  <b-modal
    id="property-auction"
    :visible="showPropertyAuctionModal"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:show-property-auction-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $attrs.title }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div />

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>
    <!-- Modal: Body -->
    <div>
      <div>
        <b-card
          class="p-0 m-0"
          style="margin-top: 0.8rem !important;"
        >
          <b-card-title style="margin-bottom: 0.4rem !important;">
            <div>
              <small
                v-if="propertyData.project !== undefined"
              >{{ `${$t('Property')}` }}</small>
              <b-alert
                v-if="propertyData.project !== undefined"
                show
                variant="success"
                style="margin-top: 0.2rem;"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="CheckCircleIcon"
                  />
                  <span class="ml-25">{{ propertyData.project }}</span>
                </div>
              </b-alert>
            </div>
            <div>
              <b-row>
                <b-col cols="6">
                  <small
                    v-if="propertyData.code !== undefined"
                  >{{ `${$t('Property Code')}` }}</small>
                  <b-alert
                    v-if="propertyData.code !== undefined"
                    show
                    variant="info"
                    style="margin-top: 0.2rem;"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="InfoIcon"
                      />
                      <span class="ml-25">{{ propertyData.code }}</span>
                    </div>
                  </b-alert>
                </b-col>
                <b-col cols="6">
                  <small
                    v-if="propertyData.license !== undefined"
                  >{{ `${$t('License Number')}` }}</small>
                  <b-alert
                    v-if="propertyData.license !== undefined"
                    show
                    variant="info"
                    style="margin-top: 0.2rem;"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="InfoIcon"
                      />
                      <span class="ml-25">{{ propertyData.license }}</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </div>
          </b-card-title>

          <div style="margin: 0rem 2rem 2rem 2rem;">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <label>{{ $t('Entries') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOption"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                />
              </b-col>

              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <div
                  v-if="canAdd === true"
                  class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
                >
                  <b-button
                    v-if="$can('create', 'Property')"
                    variant="primary"
                    class="w-50"
                    @click="addPropertyAuction"
                  >
                    {{ `${$t('Add')} ${$t('Auction')}` }}
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refTable"
            :items="propertyAuctionData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            hover
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            selectable
            select-mode="multi"
          >
            <!-- Column: Id/IsSuggest -->
            <template #cell(id)="data">
              <!-- Id -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('ID') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-info"
                        class="text-capitalize"
                      >
                        {{ data.item.id }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- IsSuggest -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="`light-${suggestVariant(data.item.is_suggest)}`"
                      >
                        {{ $t(suggestToText(data.item.is_suggest)) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Auction -->
            <template #cell(auction)="data">
              <!-- Column: AuctionDateTime -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Create') }} »
                    </span>
                    <span style="margin-right: 0.4rem;">
                      <feather-icon
                        icon="CalendarIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.auction_date_show }}</span>
                    <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                      <feather-icon
                        icon="ClockIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.auction_time_show }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- Column: AuctionBy -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('By') }} »
                    </span>
                    <span style="font-size: 16px;">
                      <b-badge
                        pill
                        variant="light-secondary"
                      >
                        {{ data.item.auction_by }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- Column: StartDateTime/StopDateTime -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Start') }} »
                    </span>
                    <span style="margin-right: 0.4rem;">
                      <feather-icon
                        icon="CalendarIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.start_date_show }}</span>
                    <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                      <feather-icon
                        icon="ClockIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.start_time_show }}</span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Stop') }} »
                    </span>
                    <span style="margin-right: 0.4rem;">
                      <feather-icon
                        icon="CalendarIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.stop_date_show }}</span>
                    <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                      <feather-icon
                        icon="ClockIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.stop_time_show }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <!-- Status -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Status') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="`light-${statusVariant(data.item.status)}`"
                        class="text-capitalize"
                      >
                        {{ $t(statusToText(data.item.status)) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- State -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="`light-${stateVariant(data.item.state)}`"
                      >
                        {{ $t(stateToText(data.item.state)) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- IsDeleted -->
              <div
                v-if="data.item.is_deleted === true"
                style="margin-top: 0.3rem;"
              >
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="`light-${deletedVariant(data.item.is_deleted)}`"
                      >
                        {{ $t(deletedToText(data.item.is_deleted)) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div
                class="text-nowrap"
                style="margin-top: 15px; text-align: left;"
              >
                <feather-icon
                  v-if="$can('write', 'Property')"
                  :id="`property-auction-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="editPropertyAuctionForm(data)"
                />
                <b-tooltip
                  v-if="$can('write', 'Property')"
                  :title="`${$t('Edit')} ${$t('Auction')}`"
                  :target="`property-auction-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />

                <feather-icon
                  v-if="$can('read', 'Property')"
                  :id="`property-auction-row-${data.item.id}-view-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="viewPropertyAuctionForm(data)"
                />
                <b-tooltip
                  v-if="$can('read', 'Property')"
                  :title="`${$t('View')} ${$t('Auction')}`"
                  :target="`property-auction-row-${data.item.id}-view-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />

                <feather-icon
                  v-if="$can('delete', 'Property')"
                  :id="`property-auction-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="deletePropertyAuction(data)"
                />
                <b-tooltip
                  v-if="$can('delete', 'Property')"
                  :title="`${$t('Delete')} ${$t('Auction')}`"
                  :target="`property-auction-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="right"
                />

              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  {{ $t('Showing {from} to {to} of {of} entries',
                        {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                  }}
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItem"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </div>
    </div>

    <property-auction-form
      v-model="shallShowPropertyAuctionFormModal"
      :auction-data="auctionData"
      :type-property-auction-form="typePropertyAuctionForm"
      @property-auction-form-update="updatePropertyAuctionForm"
      @discard-property-auction-form="discardPropertyAuctionForm"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BPagination,
  BAlert,
  BTooltip,
  BListGroup,
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeProperty from './storeProperty'
import PropertyAuctionForm from './PropertyAuctionForm.vue'
import constProperty from './constProperty'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    BAlert,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BBadge,

    vSelect,
    PropertyAuctionForm,
  },
  model: {
    prop: 'showPropertyAuctionModal',
    event: 'update:show-property-auction-modal',
  },
  props: {
    showPropertyAuctionModal: {
      type: Boolean,
      required: true,
    },
    propertyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      busy: false,
      tableColumns: [],
      perPage: 10,
      totalItem: 0,
      currentPage: 1,
      perPageOption: [2, 5, 10, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: false,
      locale: 'th',
      propertyAuctionData: [],
      shallShowPropertyAuctionFormModal: false,
      auctionData: {},
      typePropertyAuctionForm: '',
      canAdd: false,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTable ? this.$refs.refTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    locale() {
      this.refetchData()
    },
    propertyData() {
      this.getListPropertyAuction()
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('ID')} » ${this.$i18n.t('Set Suggest')}`,
      auction: `${this.$i18n.t('Auction')} » ${this.$i18n.t('Date/Time')}`,
      status: `${this.$i18n.t('Status')} » ${this.$i18n.t('Set Deleted')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      { key: 'id', sortable: true, label: msgList.id },
      { key: 'auction', sortable: false, label: msgList.auction },
      { key: 'status', sortable: false, label: msgList.status },
      { key: 'actions', label: msgList.actions },
    ]
  },
  methods: {
    getListPropertyAuction() {
      const params = {
        propertyId: this.propertyData.id,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
      }
      store
        .dispatch('store-property/getListPropertyAuction', params)
        .then(response => {
          const { list, total, canAdd } = response.data
          this.propertyAuctionData = list
          this.canAdd = canAdd
          this.totalItem = total
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    onDiscard() {
      this.$emit('discard-property-auction')
      this.$emit('update:show-property-auction-modal', false)
    },
    addPropertyAuction() {
      this.showOverlay = true
      store
        .dispatch('store-property/getDefaultPropertyAuction', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.auctionData = response.data
          this.auctionData.property_id = this.propertyData.id
          this.auctionData.property = this.propertyData
          this.typePropertyAuctionForm = 'Add'
          this.shallShowPropertyAuctionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editPropertyAuctionForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-property/getPropertyAuction', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.auctionData = response.data
          this.auctionData.property_id = this.propertyData.id
          this.auctionData.property = this.propertyData
          this.typePropertyAuctionForm = 'Edit'
          this.shallShowPropertyAuctionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewPropertyAuctionForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-property/getPropertyAuction', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.auctionData = response.data
          this.auctionData.property_id = this.propertyData.id
          this.auctionData.property = this.propertyData
          this.typePropertyAuctionForm = 'View'
          this.shallShowPropertyAuctionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deletePropertyAuction(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-property/deletePropertyAuction', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    refetchData() {
      this.getListPropertyAuction()
    },
    updatePropertyAuctionForm() {
      this.refetchData()
    },
    discardPropertyAuctionForm() {
      this.refetchData()
    },
  },
  setup() {
    if (!store.hasModule('store-property')) store.registerModule('store-property', storeProperty)
    onUnmounted(() => {
      if (store.hasModule('store-property')) store.unregisterModule('store-property')
    })

    const {
      statusVariant,
      statusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      suggestToText,
      suggestVariant,
      stateToText,
      stateVariant,
    } = constProperty()

    return {
      statusVariant,
      statusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      suggestToText,
      suggestVariant,
      stateToText,
      stateVariant,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.property-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>

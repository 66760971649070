<template>
  <b-modal
    id="member-select"
    :visible="showModalMemberSelect"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:show-modal-member-select', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $attrs.title }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div />

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <div>
      <div>
        <b-card
          border-variant="success"
          class="p-0 m-0"
          style="margin-top: 0.8rem !important;"
        >
          <b-card-title>
            <small
              v-if="memberData.fullname !== undefined"
            >{{ `${$t('Select')} ${$t('Member')}` }}</small>
            <b-alert
              v-if="memberData.fullname !== undefined"
              show
              variant="success"
              style="margin-top: 0.2rem;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">{{ memberData.fullname }}</span>
              </div>
            </b-alert>
          </b-card-title>

          <!-- Table Top -->
          <div class="m-0 mb-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="4"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>{{ $t('Entries') }}</label>
                    <v-select
                      v-model="perPage"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                </b-row>
              </b-col>

              <!-- Search -->
              <b-col
                cols="8"
              >
                <b-row>
                  <b-col cols="7" />
                  <b-col cols="5">
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        :placeholder="`${$t('Search')}...`"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>

          <!-- Table -->
          <b-table
            ref="refMemberSelectTable"
            :items="getListMemberSelect"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            hover
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
          >
            <!-- Column: Fullname -->
            <template #cell(fullname)="data">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      <feather-icon
                        icon="UserIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ `${data.item.prefix} ${data.item.name} ${data.item.lastname}` }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Mobile -->
            <template #cell(mobile)="data">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      <feather-icon
                        icon="PhoneIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.mobile }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Email -->
            <template #cell(email)="data">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      <feather-icon
                        icon="MailIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">{{ data.item.email }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div
                style="margin-top: 0.3rem;"
              >
                <b-button
                  v-if="checkSelect(data.item)"
                  size="sm"
                  variant="primary"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(data.item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Select') }}</span>
                </b-button>

                <b-button
                  v-else
                  size="sm"
                  variant="outline-success"
                  style="margin-left: 0rem;"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Selected') }}</span>
                </b-button>
              </div>
            </template>

          </b-table>

          <!-- Table Footer -->
          <div class="mx-0 mb-0">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  {{ $t('Showing {from} to {to} of {of} entries',
                        {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                  }}
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalMemberSelect"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BAlert,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import storeMember from './storeMember'
import tableMemberSelect from './tableMemberSelect'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormInput,
    BAlert,
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  model: {
    prop: 'showModalMemberSelect',
    event: 'update:show-modal-member-select',
  },
  props: {
    showModalMemberSelect: {
      type: Boolean,
      required: true,
    },
    memberData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      busy: false,
    }
  },
  methods: {
    onSelect(item) {
      this.busy = true
      this.$emit('update-member-select', { select: item, member: this.memberData })
      this.$emit('update:show-modal-member-select', false)
      this.busy = false
    },
    onDiscard() {
      this.$emit('discard-member-select')
      this.$emit('update:show-modal-member-select', false)
    },
    checkSelect(item) {
      if (item.id === this.memberData.id) {
        return false
      }
      return true
    },
  },
  setup() {
    if (!store.hasModule('store-member')) store.registerModule('store-member', storeMember)
    onUnmounted(() => {
      if (store.hasModule('store-member')) store.unregisterModule('store-member')
    })

    const {
      getListMemberSelect,
      tableColumns,
      perPage,
      currentPage,
      totalMemberSelect,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refMemberSelectTable,
      refetchData,

    } = tableMemberSelect()

    const filterMemberSelect = JSON.parse(localStorage.getItem('filterMemberSelect'))
    if (filterMemberSelect !== null) {
      searchQuery.value = (filterMemberSelect.searchQuery !== null) ? filterMemberSelect.searchQuery : ''
      perPage.value = (filterMemberSelect.perPage !== null) ? filterMemberSelect.perPage : ''
      currentPage.value = (filterMemberSelect.page !== null) ? filterMemberSelect.page : ''
      sortBy.value = (filterMemberSelect.sortBy !== null) ? filterMemberSelect.sortBy : ''
      isSortDirDesc.value = (filterMemberSelect.sortDesc !== null) ? filterMemberSelect.sortDesc : ''
      locale.value = (filterMemberSelect.language !== null) ? filterMemberSelect.language : ''
    }

    return {
      getListMemberSelect,
      tableColumns,
      perPage,
      currentPage,
      totalMemberSelect,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refMemberSelectTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableMemberSelect() {
  const toast = useToast()
  const refMemberSelectTable = ref(null)

  const msgColumns = {
    errorfetch: t2('Error fetching {module} list', { module: t('Member') }),
    pleaseSelectMember: t('Please Select Member'),
    fullname: `${t('Fullname')}`,
    mobile: `${t('Mobile')}`,
    email: `${t('Email')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'fullname', sortable: true, label: msgColumns.fullname },
    { key: 'mobile', sortable: false, label: msgColumns.mobile },
    { key: 'email', sortable: false, label: msgColumns.email },
    { key: 'actions', label: msgColumns.actions },
  ]

  const perPage = ref(5)
  const totalMemberSelect = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('fullname')
  const isSortDirDesc = ref(false)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refMemberSelectTable.value ? refMemberSelectTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMemberSelect.value,
    }
  })

  const refetchMemberSelectData = () => {
    if (refMemberSelectTable.value !== null) {
      // console.log('refresh')
      refMemberSelectTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchMemberSelectData()
  })

  const getListMemberSelect = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-member/getListMemberSelect', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalMemberSelect.value = total
        localStorage.setItem('filterMemberSelect', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgColumns.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getListMemberSelect,
    tableColumns,
    perPage,
    currentPage,
    totalMemberSelect,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,

    refMemberSelectTable,
    refetchMemberSelectData,
  }
}

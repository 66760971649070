<template>
  <div
    v-if="$can('read', 'Property')"
    style="height: inherit"
  >
    <image-light-box
      ref="refImageLightBox"
      :show-light-box="false"
      :show-caption="true"
      :show-thumbs="false"
      :disable-zoom="false"
      :media="mediaLightBox"
    />
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'Property')"
                  variant="primary"
                  class="w-50"
                  @click="addPropertyForm"
                >
                  {{ `${$t('Add')} ${$t('Property')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refPropertyTable"
          :items="getListProperty"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: CreateDate/Type/Code/License/Id -->
          <template #cell(column1)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.create_date_show }}</span>
                  <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.create_time_show }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Member') }} »
                  </span>
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-secondary"
                    >
                      {{ data.item.member }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-primary"
                    >
                      {{ data.item.type }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Property Code') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-success"
                      class="text-capitalize"
                    >
                      {{ data.item.code }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('License Number') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-warning"
                      class="text-capitalize"
                    >
                      {{ data.item.license }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-info"
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${isStatusVariant(data.item.status)}`"
                      class="text-capitalize"
                    >
                      {{ $t(isStatusToText(data.item.status)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- IsDeleted -->
            <div
              v-if="data.item.is_deleted === true"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${deletedVariant(data.item.is_deleted)}`"
                    >
                      {{ $t(deletedToText(data.item.is_deleted)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Project,Desc,Remark/Address,City -->
          <template #cell(column2)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Project') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="data.item.project" />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Description') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="data.item.description" />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Remark') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="data.item.remark" />
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Address') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span>{{ data.item.address }}</span>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <b-row>
                    <b-col cols="4">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('City') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.address }}
                      </span>
                    </b-col>
                    <b-col cols="4">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('District') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.district }}
                      </span>
                    </b-col>
                    <b-col cols="4">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Province') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.province }}
                      </span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <b-row>
                    <b-col cols="6">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Guarantee Price') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.guarantee_price }}
                      </span>
                    </b-col>
                    <b-col cols="6">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Consignment Price') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.consignment_price }}
                      </span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <b-row>
                    <b-col cols="6">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Size') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.size }}
                      </span>
                    </b-col>
                    <b-col cols="6">
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Usable Area') }} »
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.usable_area }}
                      </span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- State -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('State') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${stateVariant(data.item.state)}`"
                      class="text-capitalize"
                    >
                      {{ $t(stateToText(data.item.state)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div
              class="text-nowrap"
              style="text-align: left;"
            >
              <a
                class="action-icon"
                @click.stop="listPropertyImage(data)"
              >
                <feather-icon
                  v-if="$can('write', 'Property')"
                  :id="`property-row-${data.item.id}-image-icon`"
                  icon="ImageIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                />
                <b-tooltip
                  v-if="$can('write', 'Property')"
                  :title="`${$t('Image')} ${$t('Property')}`"
                  :target="`property-row-${data.item.id}-image-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="listPropertyLocation(data)"
              >
                <feather-icon
                  v-if="$can('write', 'Property')"
                  :id="`property-row-${data.item.id}-location-icon`"
                  icon="MapPinIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                />
                <b-tooltip
                  v-if="$can('write', 'Property')"
                  :title="`${$t('Location')} ${$t('Property')}`"
                  :target="`property-row-${data.item.id}-location-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="listPropertyAuction(data)"
              >
                <feather-icon
                  v-if="$can('write', 'Property')"
                  :id="`property-row-${data.item.id}-auction-icon`"
                  icon="AwardIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                />
                <b-tooltip
                  v-if="$can('write', 'Property')"
                  :title="`${$t('Auction')} ${$t('Property')}`"
                  :target="`property-row-${data.item.id}-auction-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>
            </div>
            <div
              class="text-nowrap"
              style="margin-top: 15px; text-align: left;"
            >

              <a
                class="action-icon"
                @click.stop="editPropertyForm(data)"
              >
                <feather-icon
                  v-if="$can('write', 'Property')"
                  :id="`property-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                />
                <b-tooltip
                  v-if="$can('write', 'Property')"
                  :title="`${$t('Edit')} ${$t('Property')}`"
                  :target="`property-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="viewPropertyForm(data)"
              >
                <feather-icon
                  v-if="$can('read', 'Property')"
                  :id="`property-row-${data.item.id}-view-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('read', 'Property')"
                  :title="`${$t('View')} ${$t('Property')}`"
                  :target="`property-row-${data.item.id}-view-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="deletePropertyForm(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'Property') && (data.item.is_deleted === false)"
                  :id="`property-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('delete', 'Property')"
                  :title="`${$t('Delete')} ${$t('Property')}`"
                  :target="`property-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalPropertys"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <property-form
        v-model="shallShowPropertyFormModal"
        :property-data="propertyData"
        :type-property-form="typePropertyForm"
        @property-form-update="updatePropertyForm"
        @discard-property-form="discardPropertyForm"
      />

      <property-image
        v-model="showPropertyImageModal"
        :title="`${$t('Image')} ${$t('Property')}`"
        :property-data="propertyImageData"
      />

      <property-location
        v-model="showPropertyLocationModal"
        :title="`${$t('Location')} ${$t('Property')}`"
        :property-data="propertyLocationData"
      />

      <property-auction
        v-model="showPropertyAuctionModal"
        :title="`${$t('Auction')} ${$t('Property')}`"
        :property-data="propertyAuctionData"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ImageLightBox from 'vue-image-lightbox'
import storeMember from '@/views/crm/member/storeMember'
import tableProperty from './tableProperty'
import storeProperty from './storeProperty'
import PropertyForm from './PropertyForm.vue'

import PropertyImage from './PropertyImage.vue'
import PropertyLocation from './PropertyLocation.vue'
import PropertyAuction from './PropertyAuction.vue'

export default {
  components: {
    PropertyForm,
    PropertyImage,
    PropertyLocation,
    PropertyAuction,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
    ImageLightBox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      showOverlay: false,
      propertyData: {},
      dialog: false,
      isShowLightBox: false,
      mediaLightBox: [{}],
      showPropertyImageModal: false,
      showPropertyLocationModal: false,
      showPropertyAuctionModal: false,
      propertyImageData: {},
      propertyLocationData: {},
      propertyAuctionData: {},
      propertyId: 0,
    }
  },
  mounted() {
    this.getDefaultProperty()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultProperty() {
      this.showOverlay = true
      store
        .dispatch('store-property/getDefaultProperty', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.propertyData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addPropertyForm() {
      this.showOverlay = true
      store
        .dispatch('store-property/getDefaultProperty', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.propertyData = response.data
          this.propertyData.user_name = this.userData.name
          this.typePropertyForm = 'Add'
          this.shallShowPropertyFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editPropertyForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-property/getProperty', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.propertyData = response.data
          this.propertyData.user_name = this.userData.name
          this.typePropertyForm = 'Edit'
          this.shallShowPropertyFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewPropertyForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-property/getProperty', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.propertyData = response.data
          this.propertyData.image_data = ''
          this.typePropertyForm = 'View'
          this.shallShowPropertyFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deletePropertyForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-property/deleteProperty', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updatePropertyForm() {
      this.refetchData()
    },
    discardPropertyForm() {
      this.refetchData()
    },
    showPopupImage(data) {
      const image = [
        {
          src: data.image_url,
          caption: data.name,
        },
      ]
      this.mediaLightBox = image
      this.$refs.refImageLightBox.showImage(0)
    },
    listPropertyImage(data) {
      this.propertyImageData = data.item
      this.showPropertyImageModal = true
    },
    listPropertyLocation(data) {
      this.propertyLocationData = data.item
      this.showPropertyLocationModal = true
    },
    listPropertyAuction(data) {
      this.propertyAuctionData = data.item
      this.showPropertyAuctionModal = true
    },
  },
  setup() {
    const shallShowPropertyFormModal = ref(false)
    const typePropertyForm = ref('')

    if (!store.hasModule('store-property')) store.registerModule('store-property', storeProperty)
    if (!store.hasModule('store-member')) store.registerModule('store-member', storeMember)
    onUnmounted(() => {
      if (store.hasModule('store-property')) store.unregisterModule('store-property')
      if (store.hasModule('store-member')) store.unregisterModule('store-member')
    })

    const {
      getListProperty,
      tableColumns,
      perPage,
      currentPage,
      totalPropertys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refPropertyTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      stateToText,
      stateVariant,
    } = tableProperty()

    const filterList = JSON.parse(localStorage.getItem('filterPropertyList'))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
    }

    return {
      shallShowPropertyFormModal,
      typePropertyForm,

      getListProperty,
      tableColumns,
      perPage,
      currentPage,
      totalPropertys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refPropertyTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      stateToText,
      stateVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.property-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/tools/sharetarget.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #429dc8 !important;
  }
}
</style>
